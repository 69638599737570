<template>
  <div class="inner-container" :class="{ 'min-container': !getTabs }">
    <stepperGeneric :steps="steps" @complete="handleCompletion"></stepperGeneric>
  </div>
</template>

<script>
import stepperGeneric from '../../components/stepper/devis101/stepperGeneric.vue';
import stepAdresseBar101 from '../../components/stepper/devis101/stepAdresseBar101.vue';
import stepChaniterBar101 from '../../components/stepper/devis101/stepChaniterBar101.vue';
import stepClientBar101 from '../../components/stepper/devis101/stepClientBar101.vue';
import stepGeneraliteBar101 from '../../components/stepper/devis101/stepGeneraliteBar101.vue';
import stepInformationBar101 from '../../components/stepper/devis101/stepInformationBar101.vue';
import stepInstallateurBar101 from '../../components/stepper/devis101/stepInstallateurBar101.vue';
import stepReglementBar101 from '../../components/stepper/devis101/stepReglementBar101.vue';
import stepDevisBar101 from '../../components/stepper/devis101/stepDevisBar101.vue';

import { mapGetters } from "vuex";

export default {
  components: {
    stepperGeneric,
  
  },
  data() {
    return {
      steps: [
        { label: 'Généralité', component: stepGeneraliteBar101 },
        { label: 'Client', component: stepClientBar101 },
        { label: 'Installateur', component: stepInstallateurBar101 },
        { label: 'Adresse', component: stepAdresseBar101 },
        { label: 'Information', component: stepInformationBar101 },
        { label: 'Chantier', component: stepChaniterBar101 },
        { label: 'Règlement', component: stepReglementBar101 },
        { label: 'Devis', component: stepDevisBar101 },
      ],
    };
  },
  computed: {
    ...mapGetters(["getTabs"]),
  },
  methods: {
    handleCompletion() {
      this.$router.push('/devis/list');
    },
  },
};
</script>

  
  <style scoped>
  .inner-container .content {
    margin: 10px 15px;
    background-color: #fff;
    box-shadow: 1px 1px 24px #00000019;
    border-radius: 5px;
    padding: 14px;
    min-height: 88vh;
    margin-top: 55px;
  }
  </style>